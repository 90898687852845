export default {
  error_occurred: 'Atvainojiet, radās kļūda. Lūdzu, pēc brīža mēģiniet vēlreiz.',
  new_content_available: "Ir jauns lapas atjauninājums, lūdzu, noklikšķiniet uz 'Atsvaidzināt', lai atkārtoti ielādētu lapu.",
  refresh_button_title: 'Atjaunot',
  force_reload_message: 'Atvainojiet, ir radusies kļūda. Mūsu komanda pie tā jau strādā. Lūdzu, pēc brīža mēģiniet atkārtoti ielādēt vietni.',
  sign_up: 'Izveidot kontu',
  service: 'Apkalpošana',
  employee: 'Darbinieks',
  select_employee: 'Izvēlieties personu',
  employees: 'Darbinieki',
  services: 'Pakalpojumi',
  duration: 'Ilgums',
  from: 'No',
  select_date: 'Izvēlieties datumu',
  no_account_question: 'Vai jums vēl nav konta?',
  name: 'Vārds',
  surname: 'Uzvārds',
  name_and_surname: 'Pilnais vārds',
  phone: 'Tālrunis',
  password: 'Parole',
  field_is_required: 'Šis lauks ir obligāts.',
  value_is_too_long: 'Vērtība ir pārāk gara.',
  value_is_too_short: 'Vērtība ir pārāk īsa.',
  signup_was_successful: 'Reģistrācija bija veiksmīga. Pierakstīšanās panelī.',
  signup_has_failed: 'Reģistrācija neizdevās. Lūdzu, pēc brīža mēģiniet vēlreiz.',
  email_is_taken: 'Konts ar norādīto e-pasta adresi jau pastāv. Ja esat aizmirsis paroli, izmantojiet atgādinājuma opciju.',
  email_or_password_incorrect: 'E-pasta adrese vai parole ir nepareiza. Ja esat aizmirsis paroli, izmantojiet atgādinājuma opciju.',
  account_not_found: 'Konts nav atrasts.',
  page_not_found: 'Hmm! Lapa, kuru meklējāt, netika atrasta :(',
  go_back_to_main_page: 'Dodieties atpakaļ uz sākumlapu un mēģiniet vēlreiz.',
  bookings: 'Rezervācijas',
  account: 'Mans Konts',
  date: 'Datums',
  cancel: 'Atcelt',
  date_from: 'Datums no',
  date_to: 'Datums līdz',
  nothing_found_here: 'Nekas netika atrasts.',
  old_password: 'Vecā parole',
  remove: 'Dzēst',
  phone_is_invalid: 'Tālruņa numurs nav pareizs.',
  book: 'Grāmata',
  sign_up_2: 'Izveidot kontu',
  log_in: 'Ielogoties',
  your_email_address: 'Tava epasta adrese',
  select: 'Izvēlieties',
  filters: 'Filtri',
  expand: 'Izvērst',
  collapse: 'Sakļaut',
  bookings_drawer_title: 'Atlasiet filtrus, lai parādītu tikai tās rezervācijas, kuras meklējat.',
  clear_filters: 'Atiestatīt filtrus',
  show_more: 'Parādīt vairāk',
  dont_you_remember_password: 'Aizmirsi savu paroli?',
  password_information: 'Parolei jāsastāv no vismaz 8 rakstzīmēm, tajā jābūt lielajam burtam, ciparam un īpašai rakstzīmei.',
  forgotten_password_button: 'Nosūtiet saiti, lai mainītu savu paroli',
  do_you_already_have_an_account: 'Vai jums jau ir konts?',
  reset_password_title: 'Iestatiet jaunu paroli',
  reset_password_subtitle: 'Ievadiet savu jauno paroli zemāk.',
  reset_password_button: 'Saglabājiet jauno paroli',
  profile: 'Profils',
  logout: 'Izrakstīties',
  cancel_booking: 'Atceliet savu rezervāciju',
  status_approved: 'Apstiprināts',
  status_payment: 'Gaida maksājumu',
  status_done: 'Pabeigts',
  status_cancelled: 'Atcelts',
  cancel_booking_dialog_title: 'Vai vēlaties atcelt savu rezervāciju?',
  cancel_booking_dialog_information: 'Šo darbību nevar atsaukt!',
  cancel_booking_dialog_button: 'Jā, atcelt manu rezervāciju',
  my_profile: 'Mans profils',
  my_data: 'Manas detaļas',
  upload_new_photo: 'Pievienojiet jaunu fotoattēlu',
  birthday: 'Dzimšanas diena',
  save_changes: 'Saglabāt izmaiņas',
  phone_number: 'Telefona numurs',
  directional: 'Reģionālais kods',
  save_new_password: 'Saglabājiet jauno paroli',
  new_password: 'Jauna parole',
  password_change: 'Mainiet paroli',
  view: 'Skatīt',
  services_2: 'Pakalpojumi',
  accept_terms_and_conditions_1: 'Noklikšķinot uz tālāk esošās opcijas, es piekrītu',
  accept_terms_and_conditions_2: 'Noteikumi un nosacījumi',
  accept_terms_and_conditions_3: 'un es apstiprinu, ka esmu izlasījis',
  accept_terms_and_conditions_4: 'Paziņojums par konfidencialitāti.',
  to: 'Uz',
  pln: '@{value} PLN',
  usd: '$@{value}',
  eur: '@{value} EUR',
  gbp: '£@{value}',
  jpy: '@{value} JPY',
  aud: '@{value} AUD',
  cad: '@{value} CAD',
  chf: '@{value} CHF',
  sek: '@{value} SEK',
  nzd: '@{value} NZD',
  brl: '@{value} BRL',
  bgn: '@{value} BGN',
  czk: '@{value} CZK',
  dkk: '@{value} DKK',
  hkd: '@{value} HKD',
  huf: '@{value} HUF',
  ils: '@{value} ILS',
  myr: '@{value} MYR',
  mxn: '@{value} MXN',
  twd: '@{value} TWD',
  nok: '@{value} NOK',
  php: '@{value} PHP',
  ron: '@{value} RON',
  rub: '@{value} RUB',
  sgd: '@{value} SGD',
  thb: '@{value} THB',
  uah: '@{value} UAH',
  inr: '@{value} INR',
  clp: '@{value} CLP',
  login: 'Ielogoties',
  email_is_incorrect: 'E-pasta adrese ir nepareiza.',
  logged_out: 'Jūs esat izrakstījies',
  booking_dialog_date_is_already_taken: 'Atlasītais laiks nav pieejams. Vai jūs vēlētos izvēlēties citu vietu?',
  data_search: 'Datu meklēšana. Lūdzu uzgaidiet...',
  no_employees: 'Nav darbinieku',
  no_services: 'Nav pakalpojumu',
  no_categories: 'Nav kategoriju',
  no_bookings: 'Rezervācijas nav atrastas.',
  no_subscriptions: 'Abonementi netika atrasti.',
  female: 'Sieviete',
  male: 'Vīrietis',
  other: 'Cits',
  no_numeric_in_password: 'Parolē nav ciparu rakstzīmes.',
  no_lowercase_in_password: 'Parolē nav mazo burtu.',
  no_uppercase_in_password: 'Parolē nav lielo burtu.',
  no_special_character: 'Nav īpaša rakstura.',
  min_n_characters: 'Minimālais rakstzīmju skaits ir @{min}.',
  max_n_characters: 'Maksimālais rakstzīmju skaits ir @{max}.',
  email: 'E-pasts',
  reset_password_fail_text: 'Parole nav mainīta. Iespējams, saitei ir beidzies derīguma termiņš.',
  reset_password_success_title: 'Jūsu parole ir nomainīta.',
  reset_password_success_text: 'Jūs varat pierakstīties ar savu jauno paroli.',
  account_activation: 'Konta aktivizēšana',
  account_activation_success: 'Konts ir aktivizēts.',
  account_activation_fail: 'Aktivizējot jūsu kontu, radās problēma. Šķiet, ka konts jau ir aktīvs vai saite vairs nav derīga.',
  account_activation_processing: 'Konta aktivizēšana. Lūdzu uzgaidiet...',
  account_activation_resend_title: 'Konts nav aktivizēts',
  account_activation_resend_text: 'Aktivizējiet savu kontu, noklikšķinot uz aktivizācijas saites e-pastā, ko mēs jums nosūtījām.',
  account_activation_resend_button: 'Sūtīt vēlreiz',
  confirm_send_activate_link_dialog_subtitle: 'Mēs esam nosūtījuši e-pastu ar aktivizācijas saiti uz iepriekš norādīto e-pasta adresi.',
  file_is_too_large: 'Atlasītais fails ir pārāk liels.',
  appointment_title: 'Izvēlieties personu, rezervācijas datumu un laiku',
  others: 'Cits',
  countryLabel: 'Valsts',
  requiredMessages: 'Nepieciešams tālruņa numurs',
  date_of_visit: 'Datums',
  hour_of_visit: 'Laiks',
  thank_you_for_booking: 'Jūsu rezervācija',
  no_free_hours: 'Atlasītajā dienā nav pieejamu laika nišu.',
  any_person: 'Nejaušas izvēles',
  password_changed: 'Jūsu parole ir nomainīta',
  incorrect_old_password: 'Vecā parole ir nepareiza',
  error404: 'Kļūda 404',
  minutes: 'min',
  hours: 'h',
  days: 'D',
  ok: 'Labi',
  web_page_temporary_disabled: 'Atvainojiet! Šī lapa tika īslaicīgi atspējota.',
  profile_update_success: 'Jūsu dati ir saglabāti.',
  form_is_invalid: 'Veidlapā ir kļūdas. Lūdzu, pārbaudiet iezīmētos laukus un mēģiniet vēlreiz.',
  search: 'Meklēt',
  pay_online_button_title: 'Maksājiet tiešsaistē',
  account_not_activated_info: 'E-pasta adrese nav apstiprināta. Apstipriniet savu e-pasta adresi, noklikšķinot uz saites nosūtītajā ziņojumā.',
  paid: 'Apmaksāts',
  payments: 'Maksājumi',
  payment_service: 'Apkalpošana',
  payment_date: 'Datums',
  payment_price: 'Cena',
  payment_success_title: 'Maksājums bija veiksmīgs, paldies!',
  payment_fail_title: 'Maksājums neizdevās, lūdzu, mēģiniet vēlreiz.',
  pay_again: 'Maksājiet vēlreiz',
  newsletter_subscribe_success: 'Paldies! Jūsu e-pasta adrese ir apstiprināta.',
  newsletter_subscribe_fail: 'Aktivizējot jūsu e-pasta adresi, radās problēma. Šķiet, ka e-pasta adrese jau ir aktīva vai saite vairs nav derīga.',
  newsletter_send_again: 'Sūtīt vēlreiz',
  newsletter_send_again_success: 'Aktivizācijas saite ir nosūtīta vēlreiz.',
  send_again_fail: 'Radās problēma, ģenerējot jaunu aktivizācijas saiti, iespējams, jūsu e-pasta adrese jau ir aktīva vai jūs tikko izveidojāt saiti.',
  newsletter_email_is_used: 'E-pasta adrese jau ir sarakstā, ja adrese nav apstiprināta, varam atkārtoti nosūtīt aktivizācijas saiti.',
  newsletter_unsubscribe_success: 'Paldies! Jūsu e-pasta adrese ir noņemta.',
  newsletter_unsubscribe_fail: 'Dzēšot e-pasta adresi, radās problēma. Šķiet, ka e-pasta adrese jau ir izdzēsta vai saitei ir beidzies derīguma termiņš.',
  booking_payments: 'Rezervācijas',
  simple_store_product_transactions: 'Produkti',
  payment_type_select_box: 'Izvēlieties maksājuma veidu',
  payment_description: 'Apraksts',
  add_to_cart: 'Pievienot grozam',
  remove_from_cart: 'Izņem no groza',
  save: 'Saglabāt',
  bookings_cart_summary: 'Rezervācijas kopsavilkums',
  today: 'Šodien',
  fill_booking_details_title: 'Ievadiet informāciju',
  create_an_account_question: 'Izveidojiet kontu un nosūtiet paroli uz e-pasta adresi',
  value_is_incorrect: 'Šī vērtība nav derīga.',
  first_available_date: 'Pieejamie laika posmi ieslēgti',
  service_location_1: 'Adrese',
  service_location_2: 'Tālrunis',
  service_location_3: 'Google Meet',
  service_location_4: 'Cits',
  service_location_5: 'Zoom',
  service_location_6: 'Microsoft Teams',
  service_location_7: 'Skype',
  service_location_8: 'WhatsApp',
  service_location: 'Atrašanās vieta',
  booking_not_found: 'Rezervācija nav atrasta.',
  booking_canceled: 'Rezervācija tika atcelta.',
  booking_canceled_fail: 'Šo rezervāciju nevar atcelt.',
  day: 'Diena',
  week: 'Nedēļa',
  month: 'Mēnesis',
  year: 'Gads',
  booking_plural_1: 'Rezervēšana',
  booking_plural_2: 'Rezervācijas',
  booking_plural_3: 'Rezervācijas',
  booking_plural_4: 'Rezervācijas',
  booking_plural_5: 'Rezervācijas',
  day_plural_1: 'Diena',
  day_plural_2: 'Dienas',
  day_plural_3: 'Dienas',
  day_plural_4: 'Dienas',
  day_plural_5: 'Dienas',
  week_plural_1: 'Nedēļa',
  week_plural_2: 'Nedēļas',
  week_plural_3: 'Nedēļas',
  week_plural_4: 'Nedēļas',
  week_plural_5: 'Nedēļas',
  month_plural_1: 'Mēnesis',
  month_plural_2: 'Mēneši',
  month_plural_3: 'Mēneši',
  month_plural_4: 'Mēneši',
  month_plural_5: 'Mēneši',
  year_plural_1: 'Gads',
  year_plural_2: 'Gadiem',
  year_plural_3: 'Gadiem',
  year_plural_4: 'Gadiem',
  year_plural_5: 'Gadiem',
  tax: 'PVN ID',
  tax_prefix: 'Priedēklis',
  get_company_data: 'Meklēt',
  get_company_data_failed: 'Datus nevarēja lejupielādēt.',
  company_name: 'Kompānijas nosaukums',
  street_and_number: 'Iela un numurs',
  postcode: 'Pasta indekss',
  city: 'Pilsēta',
  country: 'Valsts',
  subscribe: 'Abonēt',
  recurrent_fee_info: 'Rēķina katru',
  one_time_fee_info: 'Vienreizēja maksa.',
  new_account_title: 'Izveidot jaunu kontu',
  payment_data_title: 'Maksājumu informācija',
  billing_data_checkbox_question: 'Norēķinu informācija',
  confirmation_modal_header: 'Darbībai nepieciešams apstiprinājums',
  confirmation_modal_warning: 'Vai tiešām vēlaties turpināt?',
  no_way_to_undo_action: 'Šo darbību nevar atsaukt!',
  delete_payment_method: 'Noņemiet karti',
  add_payment_method: 'Pievienojiet karti',
  fill_card_details: 'Aizpildiet maksājuma informāciju.',
  subscriptions: 'Abonementi',
  subscription: 'Abonēšana',
  invoice_number: 'Rēķins',
  number: 'Numurs',
  status: 'Statuss',
  details: 'Sīkāka informācija',
  created_at: 'Izveidots',
  subscription_status_initiated: 'Neapmaksāts',
  subscription_status_active: 'Aktīvs',
  subscription_status_inactive: 'Neaktīvs',
  subscription_status_canceled: 'Atcelts',
  ends_at: 'Beidzas plkst',
  ends_at_default: 'Derīgs līdz norēķinu perioda beigām',
  select_subscription_to_pay: 'Izvēlieties abonementu, lai samaksātu.',
  pay_with_subscription: 'Maksājiet ar abonementu',
  bookings_paid_by_subscription_success_message: 'Apmaksāts',
  bookings_not_paid_by_subscription_error_message: 'Rezervāciju nevarēja apmaksāt ar izvēlēto abonementu.',
  stripe: 'Svītra',
  provider_tag: 'Metode',
  card: 'Kart',
  cash: 'Skaidra nauda',
  transfer: 'Pārsūtīšana',
  promo_code_label: 'Atlaides kods',
  promo_code_invalid_message: 'Norādītais atlaides kods nav derīgs.',
  payment_method_setup_error: 'Radās problēma ar jūsu karti. Jūsu banka ir atteikusies autorizēt karti vēlākiem darījumiem. Lūdzu, sazinieties ar savu banku.',
  active_subscription_missing: 'Lai rezervētu šo pakalpojumu, ir nepieciešams aktīvs abonements. Diemžēl jums nav abonementa, ko izmantot.',
  area_code: 'Reģionālais kods',
  usage: 'Lietošana',
  create_account_in_stripe_failed: 'Maksājumu operators ir noraidījis jūsu abonēšanas datus. Lūdzam pārbaudīt veidlapas pareizību (nodokļu reģistrācijas numurs, tālruņa numurs, e-pasta adrese).',
  additional_auth_required: 'Jūsu bankai ir nepieciešama papildu autorizācija',
  general_payment_error: 'Radās problēma ar jūsu maksājumu. Iespējams, jūsu karte neatbalsta abonementus, ir pirkuma limiti vai nav pietiekami daudz līdzekļu, lai samaksātu par abonementu. Lūdzu, sazinieties ar savu banku vai izvēlieties citu maksājuma veidu.',
  subscription_set_successfully: 'Abonements ir pareizi iestatīts.',
  booking_created: 'Jauna rezervācija',
  booking_updated: 'Rezervācijas maiņa',
  booking_deleted: 'Rezervācijas dzēšana',
  booking_reminder: 'Rezervācijas atgādinājums',
  email_notifications: 'E-pasta paziņojumi',
  sms_notifications: 'SMS paziņojumi',
  save_user_preferences: 'Saglabājiet preferences',
  free_spots: 'Vietas palikušas',
  no: 'Nē',
  yes: 'Jā',
  p24_inactive: 'Maksājumu operatoru Przelewy24 nevar izmantot. Lūdzu, sazinieties ar savu maksājumu operatoru.',
  stripe_customer_invalid: 'Nederīgs Stripe klienta ID. Maksājuma veidu nevar piesaistīt klientam.',
  stripe_resource_invalid: 'Atlasītais produkts nav pieejams. Lūdzu, pārbaudiet produkta pieejamību pie maksājumu operatora.',
  account_blocked_message: 'Jūsu konts ir neaktīvs. Lūdzu, sazinieties ar administratoru.',
  time_zone: 'Laika zona',
  no_spots_left: 'Jūs esat sasniedzis maksimālo pieejamo vietu skaitu.',
  employee_auth_required: 'Lūdzu, piesakieties savā kontā vai izmantojiet citu e-pasta adresi. Darbinieku kontiem ir jāpiesakās.',
  subpage_auth_required: 'Lūdzu, piesakieties savā kontā, lai skatītu cilni.',
  gross: 'Bruto summa.',
  subscription_login_info: 'Jau ir konts? Lūdzu, pierakstieties zemāk, lai aizpildītu savus datus.',
  change_date_time: 'Pārplānot',
  promo_code_checkbox: 'Man ir atlaides kods',
  booking_promo_code_label: 'Ievadiet atlaides kodu',
  select_hour: 'Izvēlieties laiku',
  id: 'Id',
  booking_rescheduled: 'Jūsu rezervācija ir mainīta.',
  booking_rescheduled_fail: 'Šo rezervāciju nevar mainīt.',
  max_user_bookings_error: 'Jūs esat pārsniedzis maksimālo rezervāciju skaitu. Lūdzu, izvēlieties citu pakalpojumu vai sazinieties ar mums.',
  in_total: 'Kopā',
  company: 'Uzņēmums',
  individual: 'Privātpersona',
  bulk_payment: 'Lielapjoma maksājums',
  make_bulk_payment: 'Lielapjoma maksājums',
  simple_store_product_confirmation_title: 'Aizpildiet pirkuma veidlapu',
  select_service_type: 'Izvēlieties pakalpojuma iespēju',
  add_to_calendar_button_label: 'Pievienot kalendāram',
  login_to_use_service_with_subscription: 'Lūdzu, piesakieties savā kontā, lai rezervētu tikšanos šim pakalpojumam.',
  booking_dialog_date_is_already_taken_multiple_bookings: 'Izvēlētais datums nav pieejams. Lūdzu, izvēlieties citu brīvu datumu. Rezervējot vairākus datumus, pārliecinieties, ka tie nav savstarpēji izslēdzoši.',
  select_location_calendar_warning: 'Izvēlieties vietu, lai ielādētu pieejamos laikus.',
  selectable_location_online_title: 'Tiešsaistē',
  change: 'Mainīt',
  show_less_hours_button: 'Rādīt mazāk stundu',
  show_more_hours_button: 'Rādīt vairāk stundu',
  add_another_button_title: 'Pievienot vēl vienu',
  close: 'Aizvērt',
  selected_time_slots: 'Izvēlētie laika intervāli',
  select_location: 'Izvēlieties atrašanās vietu',
  captcha_invalid_error_notification: 'Captcha verifikācija neizdevās. Mēģiniet pārlādēt lapu, ja problēma turpinās.',
  verify_code_incorrect_error: 'Ievadītais kods ir nepareizs.',
  verify_code_too_many_requests: 'Pašlaik nevaram ģenerēt jaunu kodu. Lūdzu, mēģiniet vēlāk vai izmantojiet jau nosūtīto kodu.',
  send_again_button_title: 'Sūtīt vēlreiz',
  verify_title: 'Verificēt',
  verify_code_title: 'Ievadiet verifikācijas kodu',
  verify_code_description: 'Esam nosūtījuši 6 ciparu verifikācijas kodu uz jūsu e-pastu. Lūdzu, ievadiet to zemāk:',
  verification_invalid_error_notification: 'Nederīga pārbaude. Mēģiniet vēlāk vēlreiz.',
  too_many_requests: 'Oho! Jūs rīkojaties mazliet par ātru. Palēniniet un mēģiniet vēlreiz pēc brīža.'
}
